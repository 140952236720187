import Icon from '@ant-design/icons';

const SearchSvg = () => (
    <svg version="1.1" x="0px" y="0px"
        viewBox="0 0 37.166 37.166" width="1em" height="1em">
        <path d="M35.829,32.045l-6.833-6.833c-0.513-0.513-1.167-0.788-1.836-0.853c2.06-2.567,3.298-5.819,3.298-9.359
	c0-8.271-6.729-15-15-15c-8.271,0-15,6.729-15,15c0,8.271,6.729,15,15,15c3.121,0,6.021-0.96,8.424-2.598
	c0.018,0.744,0.305,1.482,0.872,2.052l6.833,6.833c0.585,0.586,1.354,0.879,2.121,0.879s1.536-0.293,2.121-0.879
	C37.001,35.116,37.001,33.217,35.829,32.045z M15.458,25c-5.514,0-10-4.484-10-10c0-5.514,4.486-10,10-10c5.514,0,10,4.486,10,10
	C25.458,20.516,20.972,25,15.458,25z"/>
    </svg>
);

const Search = props => <Icon component={ SearchSvg } { ...props } />;

export default Search;